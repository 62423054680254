import React, { Component } from "react";
import { Redirect } from 'react-router';
import PropTypes from "prop-types";
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import { fetchJobById } from "../../redux/actions";
import { fetchEmployeesByJobId, fetchRebarByJobId, fetchCableByJobId } from "../../redux/actions";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Toolbar from '@material-ui/core/Toolbar'
import MaterialTable from "material-table";
import ImgsViewer from 'react-images-viewer'
import Loader from "./../Loader";
import { connect } from "react-redux";
import Map from "./Map";
import SteelLogEntry from "./../SteelLogEntry"
import moment from 'moment'


import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

const _ = require('lodash');
const data = [
  {
    name: "Hours A",
    uv: 4000,
    pv: 2400,
    amt: 2400
  },
  {
    name: "Hours B",
    uv: 3000,
    pv: 1398,
    amt: 2210
  },
  {
    name: "Hours C",
    uv: 2000,
    pv: 9800,
    amt: 2290
  },
  {
    name: "Hours D",
    uv: 2780,
    pv: 3908,
    amt: 2000
  },
  {
    name: "Hours E",
    uv: 1890,
    pv: 4800,
    amt: 2181
  },
  {
    name: "Hours F",
    uv: 2390,
    pv: 3800,
    amt: 2500
  },
  {
    name: "Hours G",
    uv: 3490,
    pv: 4300,
    amt: 2100
  }
];

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  form: {
    textAlign: "left"
  }, 
  padding: {
    padding: `0 ${theme.spacing.unit * 2}px`,
  }
});

class JobInfo extends Component {
  state = {
    jobInfo: [],
    employeesByJob: [],
    rebarByJob: [],
    cableByJob: [],
    value: 0,
    costCodeValue: 0,
    open: false
  };

  async componentDidMount() {
    try {
      setInterval(async () => {
       if(!this.state.open)
          this.props.fetchRebarByJobId(this.props.match.params.id)
      }, 60000);
    } catch(e) {
      console.log(e);
    }
}
  componentWillMount() {
    this.props.fetchJobById(this.props.match.params.id);
    this.props.fetchEmployeesByJobId(this.props.match.params.id);
    this.props.fetchRebarByJobId(this.props.match.params.id);
    this.props.fetchCableByJobId(this.props.match.params.id);
  }

  constructor(props) {
    super(props);
  }

  getAlertCount = () => {
    if(this.props.rebarByJob) {
      var count = _.filter(this.props.rebarByJob, function(o) { if (o.imageUrl) return o }).length;
      return count;
    }
    return 0;
  }

  handleOpen = () => {
  console.log("this is:", this);
  this.setState(open => ({
    open: !this.state.open
  }));
}


  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeSteelLog = (event, costCodeValue) => {
    this.setState({ costCodeValue });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    const { costCodeValue } = this.state;

    console.log(this.props.employeesByJob);
    return (
      <div className={classes.root}>

       <Tabs
          value={this.state.value}
          onChange={this.handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Job Info"  />
          <Tab label="Steel Log"  />
         </Tabs>
        { value === 0 && this.props.jobInfo ? (
          <Grid container spacing={24}>
            <Grid item xs={12}>
            <Typography gutterBottom variant="name" component="h2">
            {this.props.jobInfo.name}
            </Typography>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <Typography gutterBottom variant="name" component="h4">
                  Job Info
                </Typography>
                <div className={classes.form}>
                  <Typography gutterBottom variant="name" component="h2">
                    {this.props.jobInfo.address.addressLine1}
                  </Typography>
                  <Typography gutterBottom variant="name" component="h2">
                    {this.props.jobInfo.address.addressLine2}
                  </Typography>
                  <Typography gutterBottom variant="name" component="h2">
                    {this.props.jobInfo.address.city},{" "}
                    {this.props.jobInfo.address.state}{" "}
                    {this.props.jobInfo.address.zip}
                  </Typography>
                  <Typography gutterBottom variant="name" component="p">
                    {this.props.jobInfo.description}
                  </Typography>
                </div>
                <ExpansionPanel defaultExpanded={true}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>Status</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                          {this.props.valid ? (
                            <Typography gutterBottom variant="name" component="h1">
                              Current
                            </Typography>
                          ) : (
                            <Typography gutterBottom variant="name" component="h1">
                              Complete
                            </Typography>
                          )}
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>Cost Codes</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                  <List className={classes.root}>
                  {this.props.jobInfo.costCodes.map(costCode => (
                    <ListItem>
                      <ListItemText
                        primary={costCode.costCodeName}
                        secondary={costCode.value}
                      />
                    </ListItem>
                  ))}
                </List>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>Superintendents</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                  <List className={classes.root}>
                  {this.props.employeesByJob
                    ? this.props.employeesByJob.map(employee => (
                        <ListItem>
                          <ListItemText
                            primary={employee.name}
                            secondary={employee.title}
                          />
                        </ListItem>
                      ))
                    : null}
                </List>
                </ExpansionPanelDetails>
               </ExpansionPanel>
               <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>Data View</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                  <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="pv" fill="#8884d8" />
                <Bar dataKey="uv" fill="#82ca9d" />
              </BarChart>
                </ExpansionPanelDetails>
               </ExpansionPanel>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Map jobs={this.props.jobInfo} />
            </Grid>
          </Grid>
        ) : null}
        { value === 1 && this.props.rebarByJob ? ( 
          <div>
          <Tabs
          value={this.state.costCodeValue}
          onChange={this.handleChangeSteelLog}
          indicatorColor="secondary"
          textColor="secondary"
          
        >

            <Tab
            label={
              <Badge className={classes.padding} color="secondary" badgeContent={this.getAlertCount()}>
               Rebar
              </Badge>
            }
          />
            <Tab label="Cables"  />
          </Tabs>
          { costCodeValue === 0 ? (
          <MaterialTable
          columns={[
            { title: 'Date', field: 'date', type: 'date' },
            { title: 'supplier', field: 'supplier' },
            { title: 'controlCode', field: 'controlCode' },
            { title: 'description', field: 'description'},
            { title: 'weight', field: 'weight'},
            { title: 'weightInstalled', field: 'weightInstalled'},
            { title: 'notes', field: 'notes'}
          ]}
          data={this.props.rebarByJob}
          title={`Rebar for ${this.props.jobInfo.name}`}
          options={{
            pageSize: 25
          }}
          detailPanel={rowData => {
            return (
              <div>
              <SteelLogEntry entry={rowData}/>
                </div>
            )
          }}
          onRowClick={(event, rowData, togglePanel) => {
            togglePanel();
          }}
          >
          }></MaterialTable>
        ) : null} 

              { costCodeValue === 1 ? (
          <MaterialTable
          columns={[
            { title: 'Delivery Date', field: 'dateDelivered', type: 'date' },
            { title: 'memo', field: 'memo' },
            { title: 'studrails', field: 'studrails' },
            { title: 'description', field: 'description'},
            { title: 'weight', field: 'weight'},
            { title: 'weightInstalled', field: 'weightInstalled'},
            { title: 'notes', field: 'notes'}
          ]}
          data={this.props.cableByJob}
          title={`Cables for ${this.props.jobInfo.name}`}
          options={{
            pageSize: 25,
            exportButton: true,
          }
          }></MaterialTable>
        ) : null }
          </div>
        ) : null }

      </div>
      
    );
  }
}

JobInfo.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    jobInfo: state.app.jobInfo,
    employeesByJob: state.app.employeesByJob,
    rebarByJob: state.app.rebarByJob,
    cableByJob: state.app.cableByJob
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchJobById: jobId => dispatch(fetchJobById(jobId)),
    fetchEmployeesByJobId: jobId => dispatch(fetchEmployeesByJobId(jobId)),
    fetchRebarByJobId: jobId => dispatch(fetchRebarByJobId(jobId)),
    fetchCableByJobId: jobId => dispatch(fetchCableByJobId(jobId)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(JobInfo));
