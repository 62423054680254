import React, { Component } from "react";
import "./App.css";
import NavBar from "./components/NavBar";
import EmployeeList from "./components/EmployeeList";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import EmployeeForm from "./components/EmployeeForm";
import API from "./api";
import MaterialTable from "material-table";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import NavLayout from "./components/NavLayout";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#4885ed",
      dark: "#002884",
      contrastText: "#fff"
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000"
    }
  }
});

class App extends Component {
  render() {
    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <NavLayout />
        </MuiThemeProvider>
      </div>
    );
  }
}

export default App;
