import { combineReducers } from "redux";
const _ = require("lodash");
const moment = require("moment");

const INITIAL_STATE = {
  items: [],
  isFetching: false,
  error: undefined
};

function appReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "FETCH_EMPLOYEES_REQUEST":
      // This time, you may want to display loader in the UI.
      return Object.assign({}, state, {
        isFetching: true
      });
    case "FETCH_EMPLOYEES_SUCCESS":
      // Adding derived todos to state
      return Object.assign({}, state, {
        isFetching: false,
        employees: action.employees
      });
    case "FETCH_EMPLOYEES_FAILURE":
      // Providing error message to state, to be able display it in UI.
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case "ADD_EMPLOYEE_SUCCESS":
      // Providing error message to state, to be able display it in UI.
      const employeeList = state.employees;
      employeeList.push(action.employee);
      return Object.assign({}, state, {
        isFetching: false,
        employees: employeeList
      });
    case "DELETE_EMPLOYEE_SUCCESS":
      // Providing error message to state, to be able display it in UI.
      let list = state.employees;
      let employee = action.employee;
      list = _.filter(list, function(item) {
        return item._id != employee._id;
      });
      console.log(list);
      return Object.assign({}, state, {
        isFetching: false,
        employees: list
      });
    case "UPDATE_EMPLOYEE_SUCCESS":
      // Providing error message to state, to be able display it in UI.
      list = state.employees;
      employee = action.employee;

      let index = _.findIndex(list, { _id: employee._id });
      list.splice(index, 1, employee);

      return Object.assign({}, state, {
        isFetching: false,
        isUserUpdate: false,
        updatedEmployee: {},
        employees: list
      });
    case "UPDATE_EMPLOYEE_CLICKED":
      return Object.assign({}, state, {
        isUserUpdate: true,
        updatedEmployee: action.employee
      });
    case "FETCH_JOBS_REQUEST":
      return Object.assign({}, state, {
        isFetching: true
      });
    case "FETCH_JOBS_SUCCESS":
      action.jobs.forEach(
        job => (job.date = moment(job.date).format("MM/DD/YYYY"))
      );
      return Object.assign({}, state, {
        isFetching: false,
        jobs: action.jobs
      });
    case "ADD_JOB_SUCCESS":
        // Providing error message to state, to be able display it in UI.
        const jobList = state.jobs;
        jobList.push(action.job);
      return Object.assign({}, state, {
          isFetching: false,
          jobs: jobList
        });
    case "ADD_JOB_FAILURE":
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case "FETCH_JOBS_FAILURE":
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case "FETCH_JOBBYID_REQUEST":
      return Object.assign({}, state, {
        isFetching: true
      });
    case "FETCH_JOBBYID_SUCCESS":
      return Object.assign({}, state, {
        isFetching: false,
        jobInfo: action.jobInfo
      });
    case "FETCH_JOBBYID_FAILURE":
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case "FETCH_EMPLOYEESBYJOB_REQUEST":
      return Object.assign({}, state, {
        isFetching: true
      });
    case "FETCH_EMPLOYEESBYJOB_SUCCESS":
      return Object.assign({}, state, {
        isFetching: false,
        employeesByJob: action.employeesByJob
      });
    case "FETCH_EMPLOYEESBYJOB_FAILURE":
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case "FETCH_REBARBYJOB_REQUEST":
      return Object.assign({}, state, {
        isFetching: true
      });
    case "FETCH_REBARBYJOB_SUCCESS":
      return Object.assign({}, state, {
        isFetching: false,
        rebarByJob: action.rebarByJob
      });
    case "FETCH_REBARBYJOB_FAILURE":
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case "FETCH_CABLEBYJOB_REQUEST":
      return Object.assign({}, state, {
        isFetching: true
      });
    case "FETCH_CABLEBYJOB_SUCCESS":
      return Object.assign({}, state, {
        isFetching: false,
        cableByJob: action.cableByJob
      });
    case "FETCH_CABLEBYJOB_FAILURE":
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });

    default:
      return state;
  }
}

export default combineReducers({
  app: appReducer
});
