import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import API from "../api";
import { deleteEmployee } from "../redux/actions";
import { updateEmployee } from "../redux/actions";
import { connect } from "react-redux";

var QRCode = require("qrcode.react");

class Employee extends Component {
  constructor(props) {
    super(props);
  }

  handleUpdate = () => {
    this.props.updateEmployee(this.props.employee);
  };

  render() {
    return (
      <div>
        {this.props.employee ? (
          <Card>
            <CardContent>
              <Typography gutterBottom variant="name" component="h2">
                {this.props.employee.name}
              </Typography>
              <Typography gutterBottom variant="title" component="h3">
                {this.props.employee.title ? this.props.employee.title : "N/A"}
              </Typography>
              <Typography align="left" variant="title" component="h6">
                {this.props.employee.role ? this.props.employee.role : "N/A"}
              </Typography>
              <Typography align="left" variant="phone" component="h6">
                {this.props.employee.phone ? this.props.employee.phone : "N/A"}
              </Typography>
              <QRCode value={this.props.employee._id} />
            </CardContent>
            <CardActions>
              <Button size="small" color="primary" onClick={this.handleUpdate}>
                Update
              </Button>
              <Button
                size="small"
                color="secondary"
                onClick={() => {
                  console.log(this.props.employee);
                  this.props.deleteEmployee(this.props.employee);
                }}
              >
                Delete
              </Button>
            </CardActions>
          </Card>
        ) : (
          "null"
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    employees: state.app
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteEmployee: employee => dispatch(deleteEmployee(employee)),
    updateEmployee: employee => dispatch(updateEmployee(employee))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Employee);
