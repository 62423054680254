import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Job from "./../../Layout/JobDashboard";
import { addJob } from "../../redux/actions";
import { connect } from "react-redux";
const moment = require('moment');

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  }
});

class TextFields extends React.Component {
  state = {
    name: "",
    locationName: "",
    description: "",
    valid: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    zip: "",
    state: "",
  };

  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    // Typical usage (don't forget to compare props):
    // console.log(prevState);
    // console.log(prevProps);
    // console.log(this.state.app);
  }

  validate = () => {
    let isError = false;
    const errors = {};

    if (this.state.name.length < 1) {
      isError = true;
      errors.nameError = "Name is required";
    }

    if (isError) {
      this.setState({
        ...this.state,
        ...errors
      });
    }

    return isError;
  };

  handleSubmit(event) {
    const error = this.validate();

    if (!error) {

    let newJob = Object.assign({},{
        name: this.state.name,
        locationName: this.state.locationName,
        description: this.state.description,
        date: moment().format(),
        valid: true,
        address: {
            addressLine1: this.state.addressLine1,
            addressLine2: this.state.addressLine2,
            city: this.state.city,
            zip: this.state.zip,
            state: this.state.zip
        }
    });

      this.props.addJob(newJob);
      console.log(this.props);
      //Clear Form
      this.setState({
        name: "",
        locationName: "",
        description: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        zip: "",
        state: "",
      });
    }
  }

  createNewEmployee() {
    console.log("button clicked");
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    const { classes } = this.props;

    return (
    <div>
      <form  noValidate autoComplete="off">
        <div>
                <TextField
                id="name"
                label="Name"
                error={this.state.nameError}
                helperText={this.state.nameError}
                className={classes.textField}
                value={this.state.name}
                onChange={this.handleChange("name")}
                margin="normal"
                />

                <TextField
                id="locationName"
                label="Location Name"
                error={this.state.nameError}
                helperText={this.state.nameError}
                className={classes.textField}
                value={this.state.locationName}
                onChange={this.handleChange("locationName")}
                margin="normal"
                />
        </div>
        <div>
            <TextField
            id="description"
            label="Description"
            error={this.state.nameError}
            multiline={true}
            rows={2}
            rowsMax={4}
            helperText={this.state.nameError}
            className={classes.textField}
            value={this.state.description}
            onChange={this.handleChange("description")}
            margin="normal"
            />
        </div>
        <div>
        <TextField
          id="standard-required"
          label="Address Line 1"
          value={this.state.addressLine1}
          onChange={this.handleChange("addressLine1")}
          className={classes.textField}
          margin="normal"
        />
        <TextField
          id="standard-required"
          label="Address Line 2"
          value={this.state.addressLine2}
          onChange={this.handleChange("addressLine2")}
          className={classes.textField}
          margin="normal"
        />
        </div>
        <div>
        <TextField
          id="standard-required"
          label="City"
          value={this.state.city}
          onChange={this.handleChange("city")}
          className={classes.textField}
          margin="normal"
        />
        <TextField
          id="standard-required"
          label="State"
          value={this.state.state}
          onChange={this.handleChange("state")}
          className={classes.textField}
          margin="normal"
        />        
        <TextField
        id="standard-required"
        label="Zip"
        value={this.state.zip}
        onChange={this.handleChange("zip")}
        className={classes.zip}
        margin="normal"
      />
        </div>

        <Button color="primary" onClick={this.handleSubmit}>
          Create Job
        </Button>
      </form>
      <div>
    {this.props.jobs.error && 
        <small>{this.props.jobs.error.status}</small>
            }
      </div>
   </div>
    );
  }
}

TextFields.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    jobs: state.app,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addJob: job => dispatch(addJob(job))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TextFields));
