import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import NativeSelect from "@material-ui/core/NativeSelect";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Employee from "./Employee";
import { updateEmployeeData } from "../redux/actions";
import { fetchJobs } from "../redux/actions";
import { connect } from "react-redux";

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  }
});

class TextFields extends React.Component {
  state = {
    name: this.props.updatedEmployee ? this.props.updatedEmployee.name : "",
    title: this.props.updatedEmployee ? this.props.updatedEmployee.title : "",
    role: this.props.updatedEmployee ? this.props.updatedEmployee.role : "",
    phone: this.props.updatedEmployee ? this.props.updatedEmployee.phone : "",
    _id: this.props.updatedEmployee ? this.props.updatedEmployee._id : "",
    jobs: this.props.updatedEmployee ? this.props.updatedEmployee.jobs : "",
    nameError: ""
  };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    this.props.fetchJobs();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      name: this.props.updatedEmployee ? this.props.updatedEmployee.name : "",
      title: this.props.updatedEmployee ? this.props.updatedEmployee.title : "",
      role: this.props.updatedEmployee ? this.props.updatedEmployee.role : "",
      phone: this.props.updatedEmployee ? this.props.updatedEmployee.phone : "",
      _id: this.props.updatedEmployee ? this.props.updatedEmployee._id : "",
      jobs: this.props.updatedEmployee ? this.props.updatedEmployee.jobs : "",
      nameError: ""
    });
  }

  validate = () => {
    let isError = false;
    const errors = {};

    if (this.state.name.length < 1) {
      isError = true;
      errors.nameError = "Name is required";
    }

    if (isError) {
      this.setState({
        ...this.state,
        ...errors
      });
    }

    return isError;
  };

  handleSubmit(event) {
    const error = this.validate();

    if (!error) {
      delete this.state.nameError;
      this.props.updateEmployeeData(this.state);
      //Clear Form
      this.setState({
        name: "",
        title: "",
        role: "",
        jobs: "",
        nameError: ""
      });
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    const { classes } = this.props;
    console.log(this.props);
    console.log(this.state);
    return (
      <form className={classes.container} noValidate autoComplete="off">
        <div>
          <TextField
            id="name"
            label="Name"
            error={this.state.nameError}
            helperText={this.state.nameError}
            className={classes.textField}
            value={this.state.name}
            onChange={this.handleChange("name")}
            margin="normal"
          />
          <div>
            <TextField
              id="jobs"
              select
              label="Job"
              className={classes.textField}
              value={this.state.jobs != "" ? this.state.jobs : ""}
              onChange={this.handleChange("jobs")}
              SelectProps={{
                native: true,
                MenuProps: {
                  className: classes.menu
                }
              }}
              helperText="Please assign job to employee"
              margin="normal"
            >
              <option key="" value="" />
              {this.props.jobs
                ? this.props.jobs.map(option => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))
                : null}
            </TextField>
          </div>
          <TextField
            id="standard-required"
            label="Title"
            value={this.state.title}
            onChange={this.handleChange("title")}
            className={classes.textField}
            margin="normal"
          />
          <TextField
            required
            id="standard-required"
            label="Role"
            value={this.state.role}
            onChange={this.handleChange("role")}
            className={classes.textField}
            margin="normal"
          />
          <TextField
            required
            id="standard-required"
            label="Phone"
            value={this.state.phone}
            onChange={this.handleChange("phone")}
            className={classes.textField}
            margin="normal"
          />
          <Button color="primary" onClick={this.handleSubmit}>
            Update Employee
          </Button>
        </div>
      </form>
    );
  }
}

TextFields.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    updatedEmployee: state.app.updatedEmployee,
    jobs: state.app.jobs
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateEmployeeData: employee => dispatch(updateEmployeeData(employee)),
    fetchJobs: function() {
      dispatch(fetchJobs());
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TextFields));
