// EmployeesContainer.js

import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchJobs } from "../redux/actions";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import JobTable from "../components/Jobs/JobTable";
import Maps from "../components/Jobs/Maps";
import { Button } from "@material-ui/core";
import JobForm from "../components/Jobs/JobForm";


class JobDashboard extends Component {
  state = {
    jobs: []
  };

  componentWillMount() {
    this.props.fetchJobs();
  }

  handleClick() {
    console.log("this is:", this);
    this.setState(state => ({
      isAdd: !state.isAdd
    }));
  }

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Typography gutterBottom variant="name" component="h1">
          Job Dashboard
        </Typography>
        <Button color="primary" onClick={e => this.handleClick(e)}>
              Add New Job
            </Button>
            {this.state.isAdd == true ? <JobForm /> : null}
        <Paper>
          <JobTable />
        </Paper>
        <Maps jobs={this.props.jobs} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    jobs: state.app.jobs
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchJobs: function() {
      dispatch(fetchJobs());
    }
  };
}

// We are using `connect` function to wrap our component with special component, which will provide to container all needed data.
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobDashboard);
