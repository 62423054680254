import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Employee from "../components/Employee";
import TextField from "@material-ui/core/TextField";
import MaterialTable from "material-table";
import API from "../api";
import EmployeeForm from "./EmployeeForm";
import { connect } from "react-redux";
import { fetchEmployees } from "../redux/actions";

class EmployeeList extends Component {
  state = {
    employees: [],
    searchString: ""
  };

  componentDidMount() {
    // When container was mounted, we need to start fetching employees.
    this.props.fetchEmployees();
    console.log(this.props.employees);
  }

  render() {
    return (
      <div>
        <div>
          <Grid container spacing={24} style={{ padding: 24 }}>
            <Grid item xs={12} sm={6} lg={12} xl={12}>
              <EmployeeForm />
            </Grid>
          </Grid>
        </div>
        {this.state.employees ? (
          <div>
            <TextField
              style={{ padding: 24 }}
              id="searchInput"
              placeholder="Search Employees"
              margin="normal"
              onChange={this.onSearchInputChange}
            />
            <Grid container spacing={24} style={{ padding: 24 }}>
              {this.state.employees.map(currentEmployee => (
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                  <Employee employee={currentEmployee} />
                </Grid>
              ))}
            </Grid>
          </div>
        ) : (
          "No employees found"
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    employees: state.app
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchEmployees: function() {
      dispatch(fetchEmployees());
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeList);
