// EmployeesContainer.js

import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchEmployees } from "../redux/actions";
import Employee from "./Employee";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import EmployeeForm from "./EmployeeForm";
import UpdateEmployeeForm from "./UpdateEmployeeForm";
import { Button } from "@material-ui/core";
import Loader from "./Loader";

class EmployeesContainer extends Component {
  state = {
    employees: [],
    searchString: "",
    isAdd: false,
    isUpdate: false
  };

  componentDidMount() {
    // When container was mounted, we need to start fetching employees.
    this.props.fetchEmployees();
    // this.setState(state => ({
    //   isUpdate: !this.props.isUserUpdate
    // }));
  }

  handleClick() {
    console.log("this is:", this);
    this.setState(state => ({
      isAdd: !state.isAdd
    }));
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      isUpdate: nextProps.employees.isUserUpdate
    });
  }

  getUpdateEmployee(val) {
    console.log(val);
  }

  constructor(props) {
    super(props);
  }

  render() {
    console.log(this.props.employees);
    return (
      <div>
        {this.props.employees.employees ? (
          <div>
            {/* <TextField
              style={{ padding: 24 }}
              id="searchInput"
              placeholder="Search Employees"
              margin="normal"
              onChange={this.onSearchInputChange}
            /> */}
            <Button color="primary" onClick={e => this.handleClick(e)}>
              Add New Employee
            </Button>
            {this.state.isAdd == true ? <EmployeeForm /> : null}
            {this.state.isUpdate == true ? (
              <UpdateEmployeeForm employee={this.props.updatedEmployee} />
            ) : null}
            <small>You are running this application in <b>{process.env.NODE_ENV}</b> mode.</small>   
            <small>API URL: <b>{process.env.REACT_APP_API_URL}</b> mode.</small>    
 
            <Grid container spacing={24} style={{ padding: 24 }}>
              {this.props.employees.employees.map(currentEmployee => (
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                  <Employee employee={currentEmployee} />
                </Grid>
              ))}
            </Grid>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    employees: state.app
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchEmployees: function() {
      dispatch(fetchEmployees());
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeesContainer);
