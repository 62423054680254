// actions.js
const config = require("../../config");
export function fetchEmployees() {
  // Instead of plain objects, we are returning function.
  return function(dispatch) {
    // Dispatching REQUEST action, which tells our app, that we are started requesting todos.
    dispatch({
      type: "FETCH_EMPLOYEES_REQUEST"
    });
    return (
      fetch(`${config.api.url}/api/employees`)
        // Here, we are getting json body(in our case it will contain `todos` or `error` prop, depending on request was failed or not) from server response
        // And providing `response` and `body` variables to the next chain.
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            // If request was failed, dispatching FAILURE action.
            dispatch({
              type: "FETCH_EMPLOYEES_FAILURE",
              error: body
            });
          } else {
            // When everything is ok, dispatching SUCCESS action.
            dispatch({
              type: "FETCH_EMPLOYEES_SUCCESS",
              employees: body
            });
          }
        })
    );
  };
}

export function addEmployee(employee) {
  // Instead of plain objects, we are returning function.
  return function(dispatch) {
    // Dispatching REQUEST action, which tells our app, that we are started requesting todos.
    dispatch({
      type: "ADD_EMPLOYEE_REQUEST"
    });
    return (
      fetch(`${config.api.url}/api/employees`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(employee)
      })
        // Here, we are getting json body(in our case it will contain `todos` or `error` prop, depending on request was failed or not) from server response
        // And providing `response` and `body` variables to the next chain.
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            // If request was failed, dispatching FAILURE action.
            dispatch({
              type: "ADD_EMPLOYEE_FAILURE",
              error: body
            });
          } else {
            // When everything is ok, dispatching SUCCESS action.
            dispatch({
              type: "ADD_EMPLOYEE_SUCCESS",
              employee: body
            });
          }
        })
    );
  };
}

export function deleteEmployee(employee) {
  // Instead of plain objects, we are returning function.
  console.log(employee);
  return function(dispatch) {
    // Dispatching REQUEST action, which tells our app, that we are started requesting todos.
    dispatch({
      type: "DELETE_EMPLOYEE_REQUEST"
    });
    return (
      fetch(`${config.api.url}/api/employees/${employee._id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(employee)
      })
        // Here, we are getting json body(in our case it will contain `todos` or `error` prop, depending on request was failed or not) from server response
        // And providing `response` and `body` variables to the next chain.
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            // If request was failed, dispatching FAILURE action.
            dispatch({
              type: "DELETE_EMPLOYEE_FAILURE",
              error: body
            });
          } else {
            // When everything is ok, dispatching SUCCESS action.
            dispatch({
              type: "DELETE_EMPLOYEE_SUCCESS",
              employee: employee
            });
          }
        })
    );
  };
}

export function updateEmployeeData(employee) {
  // Instead of plain objects, we are returning function.
  console.log("Update", employee);
  return function(dispatch) {
    // Dispatching REQUEST action, which tells our app, that we are started requesting todos.
    dispatch({
      type: "UPDATE_EMPLOYEE_REQUEST"
    });
    return (
      fetch(`${config.api.url}/api/employees/${employee._id}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(employee)
      })
        // Here, we are getting json body(in our case it will contain `todos` or `error` prop, depending on request was failed or not) from server response
        // And providing `response` and `body` variables to the next chain.
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            // If request was failed, dispatching FAILURE action.
            dispatch({
              type: "UPDATE_EMPLOYEE_FAILURE",
              error: body
            });
          } else {
            // When everything is ok, dispatching SUCCESS action.
            dispatch({
              type: "UPDATE_EMPLOYEE_SUCCESS",
              employee: employee
            });
          }
        })
    );
  };
}

export function updateEmployee(employee) {
  return function(dispatch) {
    dispatch({
      type: "UPDATE_EMPLOYEE_CLICKED",
      employee: employee
    });
  };
}

export function fetchJobs() {
  // Instead of plain objects, we are returning function.
  return function(dispatch) {
    // Dispatching REQUEST action, which tells our app, that we are started requesting todos.
    dispatch({
      type: "FETCH_JOBS_REQUEST"
    });
    return (
      fetch(`${config.api.url}/api/jobs`)
        // Here, we are getting json body(in our case it will contain `todos` or `error` prop, depending on request was failed or not) from server response
        // And providing `response` and `body` variables to the next chain.
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            // If request was failed, dispatching FAILURE action.
            dispatch({
              type: "FETCH_JOBS_FAILURE",
              error: body
            });
          } else {
            // When everything is ok, dispatching SUCCESS action.
            dispatch({
              type: "FETCH_JOBS_SUCCESS",
              jobs: body
            });
          }
        })
    );
  };
}

export function fetchJobById(id) {
  return function(dispatch) {
    dispatch({
      type: "FETCH_JOBBYID_REQUEST"
    });
    console.log(id);
    return (
      fetch(`${config.api.url}/api/jobs/${id}`)
        // Here, we are getting json body(in our case it will contain `todos` or `error` prop, depending on request was failed or not) from server response
        // And providing `response` and `body` variables to the next chain.
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            // If request was failed, dispatching FAILURE action.
            dispatch({
              type: "FETCH_JOBBYID_FAILURE",
              error: body
            });
          } else {
            // When everything is ok, dispatching SUCCESS action.
            dispatch({
              type: "FETCH_JOBBYID_SUCCESS",
              jobInfo: body
            });
          }
        })
    );
  };
}
export function fetchEmployeesByJobId(id) {
  return function(dispatch) {
    dispatch({
      type: "FETCH_EMPLOYEESBYJOB_REQUEST"
    });
    console.log(id);
    return (
      fetch(`${config.api.url}/api/employees/jobs/${id}`)
        // Here, we are getting json body(in our case it will contain `todos` or `error` prop, depending on request was failed or not) from server response
        // And providing `response` and `body` variables to the next chain.
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            // If request was failed, dispatching FAILURE action.
            dispatch({
              type: "FETCH_EMPLOYEESBYJOB_FAILURE",
              error: body
            });
          } else {
            // When everything is ok, dispatching SUCCESS action.
            dispatch({
              type: "FETCH_EMPLOYEESBYJOB_SUCCESS",
              employeesByJob: body
            });
          }
        })
    );
  };
}

export function fetchRebarByJobId(id) {
  return function(dispatch) {
    dispatch({
      type: "FETCH_REBARBYJOB_REQUEST"
    });
    console.log(id);
    return (
      fetch(`${config.api.url}/api/rebar/jobs/${id}`)
        // Here, we are getting json body(in our case it will contain `todos` or `error` prop, depending on request was failed or not) from server response
        // And providing `response` and `body` variables to the next chain.
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            // If request was failed, dispatching FAILURE action.
            dispatch({
              type: "FETCH_REBARBYJOB_FAILURE",
              error: body
            });
          } else {
            // When everything is ok, dispatching SUCCESS action.
            dispatch({
              type: "FETCH_REBARBYJOB_SUCCESS",
              rebarByJob: body
            });
          }
        })
    );
  };
}

export function fetchCableByJobId(id) {
  return function(dispatch) {
    dispatch({
      type: "FETCH_CABLEBYJOB_REQUEST"
    });
    console.log(id);
    return (
      fetch(`${config.api.url}/api/cable/jobs/${id}`)
        // Here, we are getting json body(in our case it will contain `todos` or `error` prop, depending on request was failed or not) from server response
        // And providing `response` and `body` variables to the next chain.
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            // If request was failed, dispatching FAILURE action.
            dispatch({
              type: "FETCH_CABLEBYJOB_FAILURE",
              error: body
            });
          } else {
            // When everything is ok, dispatching SUCCESS action.
            dispatch({
              type: "FETCH_CABLEBYJOB_SUCCESS",
              cableByJob: body
            });
          }
        })
    );
  };
}

export function addJob(job) {
  // Instead of plain objects, we are returning function.
  return function(dispatch) {
    // Dispatching REQUEST action, which tells our app, that we are started requesting todos.
    dispatch({
      type: "ADD_JOB_REQUEST"
    });
    return (
      fetch(`${config.api.url}/api/jobs`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(job)
      })
        // Here, we are getting json body(in our case it will contain `todos` or `error` prop, depending on request was failed or not) from server response
        // And providing `response` and `body` variables to the next chain.
        .then(res => res.json())
        .then((response) => {
          if (!response.status != 200) {
            // If request was failed, dispatching FAILURE action.
            dispatch({
              type: "ADD_JOB_FAILURE",
              error: response.message
            });
          } else {
            // When everything is ok, dispatching SUCCESS action.
            dispatch({
              type: "ADD_JOB_SUCCESS",
              job: response.body
            });
          }
        })
    );
  };
}