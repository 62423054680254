import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/Save';
import ImgsViewer from 'react-images-viewer'

const logo = require('../devao.png');

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },

});

function SteelLog(props) {
  const { classes } = props;
  console.log(props);
  return (
    <div className={classes.root}>
      <Grid container spacing={24}>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
          {/* <Button variant="contained" color="primary" className={classes.button}>
              Send
              <Icon className={classes.rightIcon}>send</Icon>
            </Button> */}

          <img width="75%" src={props.entry.imageUrl} alt="Image Not Found"/>
          </Paper>
          
        </Grid>
        <Grid item xs={6}>
        <Paper className={classes.paper}>
        <form className={classes.container} noValidate autoComplete="off">
        <Typography gutterBottom variant="title" component="h3">
              Update Log
            </Typography>
        <TextField
          id="standard-name"
          label="Weight"
          value={props.entry.weight}
          margin="normal"
        />
        </form>
        </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

SteelLog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SteelLog);