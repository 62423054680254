import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Employee from "./Employee";
import { addEmployee } from "../redux/actions";
import { connect } from "react-redux";

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  }
});

class TextFields extends React.Component {
  state = {
    name: "",
    title: "",
    role: "",
    phone: "",
    nameError: ""
  };

  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  validate = () => {
    let isError = false;
    const errors = {};

    if (this.state.name.length < 1) {
      isError = true;
      errors.nameError = "Name is required";
    }

    if (isError) {
      this.setState({
        ...this.state,
        ...errors
      });
    }

    return isError;
  };

  handleSubmit(event) {
    const error = this.validate();

    if (!error) {
      this.props.addEmployee(this.state);
      //Clear Form
      this.setState({
        name: "",
        title: "",
        role: "",
        nameError: ""
      });
    }
  }

  createNewEmployee() {
    console.log("button clicked");
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    const { classes } = this.props;

    return (
      <form className={classes.container} noValidate autoComplete="off">
        <TextField
          id="name"
          label="Name"
          error={this.state.nameError}
          helperText={this.state.nameError}
          className={classes.textField}
          value={this.state.name}
          onChange={this.handleChange("name")}
          margin="normal"
        />

        <TextField
          id="standard-required"
          label="Title"
          value={this.state.title}
          onChange={this.handleChange("title")}
          className={classes.textField}
          margin="normal"
        />

        <TextField
          required
          id="standard-required"
          label="Role"
          value={this.state.role}
          onChange={this.handleChange("role")}
          className={classes.textField}
          margin="normal"
        />

        <TextField
          id="standard-required"
          label="Phone"
          value={this.state.phone}
          onChange={this.handleChange("phone")}
          className={classes.textField}
          margin="normal"
        />

        <Button color="primary" onClick={this.handleSubmit}>
          Create Employee
        </Button>
      </form>
    );
  }
}

TextFields.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    employees: state.app
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addEmployee: employee => dispatch(addEmployee(employee))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TextFields));
