import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import MapMarkerIcon from "@material-ui/icons/LocationOn";
import IconButton from "@material-ui/core/IconButton";
import MailIcon from "@material-ui/icons/Mail";
import Marker from "./Marker";

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 39.962169,
      lng: -83.000595
    },
    zoom: 13
  };

  constructor(props) {
    super(props);
  }

  render() {
    console.log("rendered");
    return (
      <div style={{ height: "50vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyDmVWMr5vZTfXCQ8NNWSBPHg2mSW2ws3EY"
          }}
          defaultCenter={{
            lat: this.props.jobs.address.lat,
            lng: this.props.jobs.address.lng
          }}
          defaultZoom={this.props.zoom}
          defaultOptions={{
            scrollwheel: false
          }}
        >
          {this.props.jobs ? (
            <Marker
              lat={this.props.jobs.address.lat}
              lng={this.props.jobs.address.lng}
              text={this.props.jobs.name}
              job={this.props.jobs}
            />
          ) : null}
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;
