import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import GoogleMapReact from "google-map-react";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import MapMarkerIcon from "@material-ui/icons/LocationOn";
import IconButton from "@material-ui/core/IconButton";
import MailIcon from "@material-ui/icons/Mail";
import MarkerPopOver from "./MarkerPopOver";

const styles = theme => ({
  typography: {
    margin: theme.spacing.unit * 2
  }
});

class Marker extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null
    });
  };

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        <IconButton onClick={this.handleClick} color={"secondary"} fontSize="large" aria-label="Marker">
          <MapMarkerIcon />
        </IconButton>
        <Popover
          id="simple-popper"
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <MarkerPopOver job={this.props.job} />
        </Popover>
      </div>
    );
  }
}

Marker.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Marker);
