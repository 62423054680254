import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import MapMarkerIcon from "@material-ui/icons/LocationOn";
import IconButton from "@material-ui/core/IconButton";
import MailIcon from "@material-ui/icons/Mail";
import Marker from "./Marker";

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 39.962169,
      lng: -83.000595
    },
    zoom: 13
  };

  constructor(props) {
    super(props);
  }

  render() {
    console.log("rendered");
    return (
      <div style={{ height: "50vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyDmVWMr5vZTfXCQ8NNWSBPHg2mSW2ws3EY",
            v: "3.30"
          }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          {this.props.jobs
            ? this.props.jobs.map(job => (
                <Marker
                  lat={job.address.lat}
                  lng={job.address.lng}
                  text={job.name}
                  job={job}
                />
              ))
            : null}
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;
