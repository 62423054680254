import React, { Component } from "react";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import { fetchJobs } from "../../redux/actions";
import MaterialTable from "material-table";
const moment = require("moment");

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  }
});

class JobTable extends Component {
  state = {
    jobs: []
  };

  componentWillMount() {
    this.props.fetchJobs();
  }

  constructor(props) {
    super(props);
  }

  handleOnClick = () => {
    // some action...
    // then redirect
    this.setState({ redirect: true });
  };

  render() {
    return (
      <div>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Job Name</TableCell>
                <TableCell align="right">Location</TableCell>
                <TableCell align="right">Address</TableCell>
                <TableCell align="right">Date Created</TableCell>
                <TableCell align="right">Valid</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.jobs
                ? this.props.jobs.map(row => (
                    <TableRow hover={true} key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.locationName}</TableCell>
                      <TableCell align="right">
                        {row.address.addressLine1}
                      </TableCell>
                      <TableCell align="right">{row.date}</TableCell>
                      <TableCell align="right">
                        {row.valid ? "Current" : "Complete"}
                      </TableCell>
                      <TableCell align="right">
                        <Link
                          button
                          component={RouterLink}
                          params={{ id: row._id }}
                          to={`/jobs/${row._id}`}
                        >
                          View Job
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))
                : "No jobs found"}
            </TableBody>
          </Table>
        </Paper>
        {/* <div>
          <MaterialTable
            columns={[
              { title: "Job Name", field: "name" },
              { title: "Location Name", field: "locationName" },
              { title: "Address", field: "address.addressLine1" },
              {
                title: "Date Created",
                field: "date",
                type: "date"
              }
            ]}
            data={this.props.jobs}
            title="Jobs"
            actions={[
              {
                icon: "create",
                tooltip: "Edit Job",
                onClick: (event, rowData) => {
                  alert("You clicked user " + rowData.name);
                }
              },
              {
                icon: "input",
                tooltip: "View Job",
                onClick: (event, rowData) => {
                  this.renderRedirect(rowData._id);
                }
              }
            ]}
            options={{
              columnsButton: true,
              exportButton: true,
              actionsColumnIndex: -1
            }}
          />
        </div> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    jobs: state.app.jobs
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchJobs: function() {
      dispatch(fetchJobs());
    }
  };
}

// We are using `connect` function to wrap our component with special component, which will provide to container all needed data.
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobTable);
